import dayjs from 'dayjs'

export const adminState = {
  test: 'data from pinia adminState',
  activenavitem: -1,
  uilevel: -1,
  lang: 'nb',
  servername: 'http://localhost:3033/',
  serverurl: '',
  publickey: '',
  appsecret: '',
  sid: '',
  environment: 'stage',
  registryurl: 'http://localhost',
  isdisabled: false,
  lastInteraction: dayjs(),
  resetTimeLimit: 25, // should be 25 minutes
  isPhone: false,
  services: new Set(),
  radioChannel: null,
  showRadioControls: false
}
