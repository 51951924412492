import axios from 'axios'
import { encodeLoad, decodeLoad } from '@/lib/base64.js'

import { userStore } from '@/stores/userstore.js'
import dayjs from 'dayjs'

// import { CreateHypatiaJWE } from '@/lib/createJWT'

// import { base64 } from 'base64-url'

// import { jwt } from 'jsonwebtoken'
// import * as jose from 'jose'

export const adminActions = {
  setTest(text) {
    console.log('set TEST got called (%s)', text)
    this.test = text
  },
  setSelectedService(serviceid) {
    console.log('setSelectedService (%s)', serviceid)
    this.services.add(serviceid)
  },
  /* Should be the same i both adminweb and user*/
  isActiveService(serviceid) {
    return this.services.has(serviceid)
  },
  setActiveNavItem(navItem) {
    this.activenavitem = navItem
  },
  setUiLevel(level) {
    this.uilevel = level
  },
  setLang(lang) {
    this.lang = lang
  },
  setServername(server) {
    this.servername = server
  },
  setRegistryUrl(server) {
    this.registryurl = checkRegistryURL(server)
  },
  setServerUrl(server) {
    this.serverurl = checkRegistryURL(server)
  },
  setSid(sessionId) {
    this.sid = sessionId
  },
  setEnvironment(environment) {
    this.environment = environment
  },
  setIsDisabled(dis) {
    this.isdisabled = dis
  },
  //  setDataFrominfoUrl(payload) {
  async setSchema() {
    console.log('SET MEMAS Schema got called ! ')
    return new Promise((resolve, reject) => {
      const uStore = userStore()
      // let serverUrl = this.serverurl
      // let accessClientToken = userStore.serveraccesstoken
      // let accessClientToken = context.getters.getIdToken
      // console.info('========= idToken !!: ' + accessClientToken)
      let pLoad = {
        p1: uStore.sid,
        gogl: true
      }
      console.log('PLOAD IS ', pLoad)
      const instance = this.getAxiosConnection('')

      console.log('WE HAVE INSTANCE (%s) (%j)', this.serverurl, instance)
      console.log('Accesstoken (%s)', uStore.serveraccesstoken)
      instance
        .post('dgraphschema/setschema', pLoad)
        .then((res) => {
          console.log('RES FROM Set Schema', res)
        })
        .catch((error) => {
          console.log('GET SERVER INSTANCE ERROR ')
          if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            console.log('*** ERRRO ***')
            console.log(error.response.data)
            console.log(error.response.status)
            console.log(error.response.headers)
          } else if (error.request) {
            console.log('*** ERRRO REQUST ***')
            // The request was made but no response was received
            // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
            // http.ClientRequest in node.js
            console.log(error.request)
          } else {
            // Something happened in setting up the request that triggered an Error
            console.log('Error', error.message)
          }
          console.log('*** ERROR *** CONFIG')
          console.log(error.config)
        })
      /*
      instance
        .post('', pLoad)
        .then((response) => {
          console.log('setSchema response: ')
          resolve(response)
        })
        .catch((error) => {
          console.error('updateSchema had an error: ', error)
          reject(new Error('updateSchema had an error: ', error))
        })
        */
    }) // promise
  },
  async getPingServer() {
    console.log('GET PING SERVER (%s)', this.serverurl)
    return new Promise((resolve, reject) => {
      const pingserver = this.getAxiosConnection2('kubeheartbeat/')
      pingserver
        .get('isalive')
        .then((result) => {
          console.log('getPingServer er: (' + JSON.stringify(result) + ')')
          resolve(result.data)
        })
        .catch((error) => {
          console.error('*** ERROR *** getPingServer had some errors:', error)
          reject({
            errorMsg: '*** ERROR *** getPingServer had some errors:' + error
          })
        })
    }) // promise
  },
  async addRegistryUrl() {
    console.log('addRegistryUrl got called:(' + this.servername + ')')
    // let serverUrl = payload.serverURL
    return new Promise((resolve, reject) => {
      // getURLHost(serverUrl)
      const clientServer = this.getAxiosConnection3(
        this.servername,
        'lsregistry/'
      )
      // console.log('============ got client Server ======== (' + serverUrl + ')')
      clientServer
        .get('index.json')
        .then((result) => {
          console.log(
            'REGISTRY Server er: (' + JSON.stringify(result.data) + ')'
          )
          let registryUrl = ''
          if (result.data.registry_server_port === '') {
            registryUrl = result.data.registry_server
          } else {
            registryUrl =
              result.data.registry_server +
              ':' +
              result.data.registry_server_port
          }
          // context.commit('setServerUrl', payload)
          localStorage.setItem('memasregistry', checkRegistryURL(registryUrl))
          this.registryurl = checkRegistryURL(registryUrl)
          const saServer = this.getAxiosConnection3(this.registryurl, 'ls/')
          console.log('>>> GETTING SERVER FROM REGISTRY (ls/memasserver)')
          saServer
            .get('memasserver')
            .then((result) => {
              console.log('setServerUrl/memasServer/get got:')
              console.log(result)
              // console.log(result.data)
              if (result.data.error !== undefined) {
                reject({
                  error: '*** ERROR *** no result from registry service.',
                  errormsg:
                    'The Learning Registry service need to be configured !'
                })
              }
              let serverURL = checkURL(result.data.hostname, result.data.port)
              // console.log('---- serverURL (' + serverURL + ')')
              localStorage.setItem('memasserver', serverURL)
              this.setServerUrl(serverURL)
              resolve({
                result: serverURL
              })
            })
            .catch((error) => {
              console.error(
                '*** ERROR *** addRegistryUrl/getting memasserver info (' +
                  error +
                  ')'
              )
              reject({
                error:
                  '*** ERROR *** addRegistryUrl/getting memasserver info :',
                errormsg: error
              })
            })
        })
        .catch((error) => {
          console.error(
            '*** ERROR *** addRegistryUrl getting registry service: '
          )
          console.error(error)
          reject({
            error: '*** ERROR *** addRegistryUrl/getting registry service: ',
            errormsg: error
          })
        })
    }) // promise
    // context.commit('setServerUrl', payload.serverURL)
  },
  testPromise(payload) {
    console.log('testPromise got called ')
    console.log(payload)
    //  console.log(JSON.stringify(payload))
    return new Promise((resolve) => {
      resolve({
        success: false,
        error: true,
        message: 'hopp og hei'
      })
    }) // promise
  },
  getAxiosConnection(path) {
    const uStore = userStore()
    let url = this.serverurl
    let accessClientToken = uStore.serveraccesstoken
    // let accessClientToken = context.getters.getAccessToken
    // let url = context.getters.getServerUrl
    console.log('getAxiosConnection url(' + url + ') path(' + path + ')')
    this.lastInteraction = dayjs()
    return axios.create({
      baseURL: checkRegistryURL(url) + path,
      timeout: 30000,
      responseType: 'json', // default
      responseEncoding: 'utf8', // default
      maxRedirects: 8, // default
      'Accept-Encoding': 'gzip, compress',
      withCredentials: true,
      jar: true,
      headers: {
        Authorization: 'Bearer ' + accessClientToken,
        'Content-Type': 'application/json',
        Accept: 'application/json',
        memasclient: 'betterhealthforall' // for Cloud armor firewall rules
      }
    })
  },
  getAxiosConnectionImage(path) {
    const uStore = userStore()
    let url = this.serverurl
    let accessClientToken = uStore.serveraccesstoken
    // let accessClientToken = context.getters.getAccessToken
    // let url = context.getters.getServerUrl
    this.lastInteraction = dayjs()
    console.log('getAxiosConnectionImage url(' + url + ') path(' + path + ')')
    return axios.create({
      baseURL: checkRegistryURL(url) + path,
      timeout: 60000, // 1 minute
      responseType: 'json', // default
      responseEncoding: 'utf8', // default
      maxRedirects: 8, // default
      'Accept-Encoding': 'gzip, compress',
      withCredentials: true,
      jar: true,
      headers: {
        Authorization: 'Bearer ' + accessClientToken,
        'Content-Type': 'multipart/form-data',
        'Cache-Control': 'public, max-age=3600',
        Accept: 'application/json',
        memasclient: 'betterhealthforall' // for Cloud armor firewall rules
      }
    })
  },
  getAxiosConnection2(url, path) {
    // let accessClientToken = context.getters.getAccessToken
    // let url = context.getters.getServerUrl
    this.lastInteraction = dayjs()
    console.log('getAxiosConnection2 url(' + url + ') path(' + path + ')')
    return axios.create({
      baseURL: checkRegistryURL(url) + path,
      timeout: 15000,
      responseType: 'json', // default
      responseEncoding: 'utf8', // default
      maxRedirects: 3, // default
      'Accept-Encoding': 'gzip, compress',
      headers: {
        memasclient: 'betterhealthforall',
        'Content-Type': 'application/json',
        Accept: 'application/json'
      }
    })
  },
  getAxiosConnection3(url, path) {
    // let accessClientToken = context.getters.getAccessToken
    // let url = context.getters.getServerUrl
    this.lastInteraction = dayjs()
    console.log('getAxiosConnection3 url(' + url + ') path(' + path + ')')
    return axios.create({
      baseURL: checkRegistryURL(url) + path,
      timeout: 15000,
      responseType: 'json', // default
      responseEncoding: 'utf8', // default
      maxRedirects: 3, // default
      'Accept-Encoding': 'gzip, compress',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json'
      }
    })
  },
  setLastInteraction() {
    console.log('__SET LAST INTERACTION __ (%s)', dayjs().format())
    this.lastInteraction = dayjs()
  },
  /* Should be the same i both adminweb and user*/
  getServices() {
    return [
      {
        label: 'today',
        value: 'services.today', // is mapped $t('services.today')
        default: true
      },
      {
        label: 'selfreport',
        value: 'services.selfreport',
        default: false
      },
      {
        label: 'brain',
        value: 'services.brain',
        default: false
      },
      {
        label: 'dailyhelp',
        value: 'services.dailyhelp',
        default: false
      },
      {
        label: 'entertainment',
        value: 'services.entertainment',
        default: false
      },
      {
        label: 'contact',
        value: 'services.contact',
        default: true
      },
      {
        label: 'workhelp',
        value: 'services.workhelp',
        default: false
      },
      {
        label: 'phoneweekcalendar',
        value: 'services.phoneweekcalendar',
        default: false
      },
      {
        label: 'advice',
        value: 'services.advice',
        default: false
      },
      {
        label: 'blodpressure',
        value: 'services.blodpressure',
        default: false
      },
      {
        label: 'cognitiveloss',
        value: 'services.cognitiveloss',
        default: false
      },
      {
        label: 'diabetes1',
        value: 'services.diabetes1',
        default: false
      },
      {
        label: 'diabetes2',
        value: 'services.diabetes2',
        default: false
      },
      {
        label: 'dialog',
        value: 'services.dialog',
        default: false
      },
      {
        label: 'elearning',
        value: 'services.elearning',
        default: false
      },
      {
        label: 'exercise',
        value: 'services.exercise',
        default: false
      },
      {
        label: 'heartfailure',
        value: 'services.heartfailure',
        default: false
      },
      {
        label: 'knowledge',
        value: 'services.knowledge',
        default: false
      },
      {
        label: 'kols',
        value: 'services.kols',
        default: false
      },
      {
        label: 'livinghelp',
        value: 'services.livinghelp',
        default: false
      },
      {
        label: 'nutrition',
        value: 'services.nutrition',
        default: false
      },
      {
        label: 'obesity',
        value: 'services.obesity',
        default: false
      },
      {
        label: 'schoolhelp',
        value: 'services.schoolhelp',
        default: false
      },
      {
        label: 'smartwatch',
        value: 'services.smartwatch',
        default: false
      },
      {
        label: 'status',
        value: 'services.status',
        default: false
      }
    ]
  }
}

function checkRegistryURL(url) {
  if (url.length > 0) {
    let lastChar = url.charAt(url.length - 1)
    // console.log('CHECK REGISTRY URL (%o) lastchar(%o)', url, lastChar)
    if (lastChar === '/') {
      return url
    } else {
      return url + '/'
    }
  } else {
    console.error('NO URL PROVIDED !!! ')
    return ''
  }
}

function checkURL(url, port) {
  console.log('checkURL : (' + url + ') (' + port + ')')
  let newUrl = ''
  if (port === null || port === undefined) {
    port = ''
  }
  if (url.slice(0, 5) === 'https' && port === '') {
    newUrl = url
  } else if (url.slice(0, 5) === 'https' && port !== '') {
    newUrl = url + ':' + port
  } else if (url.slice(0, 4) === 'http' && port === '') {
    newUrl = url
  } else if (url.slice(0, 4) === 'http' && port !== '') {
    newUrl = url + ':' + port
  } else if (port === '443') {
    newUrl = 'https://' + url
  } else if (port !== '') {
    newUrl = 'http://' + url + ':' + port
  } else {
    newUrl = 'http://' + url
  }
  return newUrl
}
