{
  "companyname": "Karde AS",
  "nav": {
    "nav0": "Acasă",
    "nav1": "Despre Memas",
    "nav2": "Politica de confidenţialitate",
    "nav3": "NY NAV 3"
  },
  "services": {
    "today": "Astăzi",
    "selfreport": "Auto-raportare",
    "brain": "Activitati cognitive",
    "dailyhelp": "Ajutorul zilnic",
    "entertainment": "Divertisment",
    "contact": "Contact",
    "workhelp": "Asistență profesională",
    "phoneweekcalendar": "Agenda săptămânală pe telefon"
  },
  "gui": {
    "appname": "Memas",
    "close": "Închide",
    "landingpageheader": "Bine ați venit la Memas",
    "firsttimeheader": "Înregistrare inițială",
    "offlineheading": "Ești deconectat de la internet",
    "aboutheader": "Despre memas",
    "privacyheader": "Politica de confidenţialitate",
    "offlinetext": "Așteaptă până când ești conectat din nou la internet",
    "onlineheading": "Ești conectat la internet",
    "onlinetext": "Poți să-ți continui munca",
    "primaryusers": "Utilizator primar",
    "calendar": "Calendar",
    "pcalendar": "Astazi",
    "memorygames": "Activitati cognitive",
    "images": "Imagini si categorii de imagini",
    "pleasure": "Divertisment",
    "contact": "Contact",
    "selfreporting": "Auto-raportare",
    "dailyhelp": "Ajutorul zilnic",
    "usermanuals": "Manuale de utilizare",
    "primarylanding": "Utilizator primar",
    "of": "de",
    "question": "Întrebare",
    "wrongOTPcode": "Codul introdus nu există - încearcă din nou",
    "missingValue": "Trebuie să selectezi o valoare pentru a continua",
    "nextquestion": "Următoarea",
    "previousquestion": "Anterioara",
    "donequestions": "Înapoi la ecranul principal",
    "maxscreen": "Maximizează ecranul",
    "imagelibrary": "Imagini",
    "noimagelibrary": "Nu există imagini disponibile",
    "radiostations": "Radio",
    "noradiostations": "Nicio stație radio disponibilă",
    "videolibrary": "Videoclipuri",
    "novideolibrary": "Niciun videoclip disponibil",
    "stop": "Stop",
    "pause": "Pauză",
    "play": "Redare",
    "reset": "Resetare",
    "calnav": "Ziua",
    "calmonth": "Luna",
    "contactinformation": "Informații de contact",
    "replay": "Redă din nou",
    "completed": "Felicitări, ai completat întrebările",
    "moves": "Mișcări:",
    "noimages": "Nu există imagini",
    "noimagesfound": "Nu există imagini pentru a fi afișate",
    "nomemoryimages": "Joc de memorie",
    "nomemoryimagesfound": "Momentan nu sunt jocuri configurate pentru tine",
    "workhelp": "Asistență profesională",
    "picturememorygame": "Asoociază imaginile",
    "week": "Săptămâna",
    "addcalendarevent": "Noua numire",
    "todayis": "Astăzi este "
  },
  "sudoku": {
    "gamefinished": "Jocul este terminat",
    "newgame": "Joc nou",
    "viewgame": "Vizualizează jocul",
    "notes": "Notițe",
    "on": "Pornit",
    "off": "Oprit",
    "solve": "Rezolvă",
    "reset": "Resetează",
    "delete": "Șterge"
  },
  "ms": {
    "title": "Minesweeper",
    "playagain": "Joacă din nou",
    "yes": "Da",
    "win": "Victorie - ai câștigat",
    "loose": "Ai pierdut...",
    "instructions": "Evită bombele, selectează o celulă, click dreapta pentru a marca o bombă."
  },
  "time": {
    "beforelunch": "Înainte de prânz",
    "lunch": "Prânz",
    "afterlunch": "După prânz",
    "dinner": "Cină",
    "afternoon": "După-amiază",
    "evening": "Seară",
    "night": "Noapte",
    "morning": "Dimineață"
  },
  "webgame": {
    "timehint": "Durata de timp în minute pentru acest joc",
    "timelabel": "Minute",
    "timeprefix": ": ",
    "timesuffix": " min.",
    "notnumber": "Trebuie să introduci un număr!"
  },
  "tooltip": {
    "help": "Ajutor contextual pentru utilizarea Memas"
  },
  "primary": {
    "entercode": "Introdu codul de înregistrare pentru a începe utilizarea Memas"
  },
  "secondaryuser": {
    "heading": "Înregistrarea utilizatorului secundar",
    "displayname": "Nume",
    "email": "E-mail",
    "phone": "Telefon",
    "skype": "Skype",
    "address": "Adresă",
    "postcode": "Cod poștal",
    "place": "Localitate",
    "country": "Țară",
    "subscriptiontype": "Tipul de abonament",
    "subscriptionmodel1": "Gratuit - 30 de zile",
    "subscriptionmodel2": "Abonament pentru 3 luni - reînnoire automată",
    "subscriptionmodel3": "Abonament pentru 1 an - reînnoire automată"
  },
  "primaryuser": {
    "heading": "Înregistrarea utilizatorului primar",
    "displayname": "Nume",
    "email": "E-mail",
    "phone": "Telefon",
    "skype": "Skype",
    "address": "Adresă",
    "postcode": "Cod poștal",
    "place": "Localitate",
    "country": "Țară",
    "registercode": "Cod de înregistrare"
  },
  "cal": {
    "starttime": "Timp de începere",
    "endtime": "Timp de încheiere",
    "title": "Titlul evenimentului",
    "alldayevent": "Eveniment pe tot parcursul zilei",
    "explanation": "Informații despre eveniment",
    "image": "Imaginea unei persoane în legătură cu evenimentul",
    "nocaldatatoday": "Nu sunt evenimente înregistrate pentru astăzi",
    "nocaldataweek": "Nu sunt evenimente înregistrate pentru această săptămână"
  },
  "help": {
    "title": "Cum să folosești Memas",
    "headerhelp": "Asistență contextuală pentru utilizarea Memas",
    "landingpage": "https://help.v4l.no/v4l/learner/startpage/",
    "todayswrittenassignments": "https://help.v4l.no/v4l/learner/todayswrittenassignemnts/",
    "todayswrittenassignments-assignedtask": "https://help.v4l.no/v4l/learner/todayswrittenassignemntstask/",
    "writetext": "https://help.v4l.no/v4l/learner/writetext/",
    "writetext-feedback": "https://help.v4l.no/v4l/learner/writetextfeedback/",
    "writetext-competencygoal": "https://help.v4l.no/v4l/learner/writetextcompetencygoal/",
    "peerreview": "https://help.v4l.no/v4l/learner/peerreview/",
    "assessmentfeedback": "https://help.v4l.no/v4l/learner/assessmentfeedback/",
    "assessmentfeedback-assignment": "https://help.v4l.no/v4l/learner/assessmentfeedbackassignment/",
    "assessmentfeedback-view": "https://help.v4l.no/v4l/learner/assessmentfeedbackview/",
    "resultpeerreview": "https://help.v4l.no/v4l/learner/resultpeerreview/",
    "configsettings": "https://help.v4l.no/v4l/learner/configsettings/",
    "writetextaflmethod": "https://help.v4l.no/v4l/learner/writetextaflmethod/",
    "writetextautofeedback": "https://help.v4l.no/v4l/learner/writetextautofeedback/"
  },
  "icon": {
    "save": "mdi-content-save-outline",
    "schemaupdate": "mdi-update",
    "close": "mdi-close",
    "help": "mdi-help",
    "next": "mdi-arrow-right-bold",
    "previous": "mdi-arrow-left-bold",
    "dots": "mdi-dots-vertical",
    "tryagain": "mdi-refresh",
    "maxscreen": "mdi-fullscreen",
    "stop": "mdi-radio-off",
    "pause": "mdi-pause",
    "play": "mdi-play",
    "reset": "mdi-notification-clear-all",
    "reload": "mdi-reload"
  },
  "wordgame": {
    "title": "Reformulează cuvântul",
    "startgame": "Apasă pentru a începe jocul",
    "correct": "Răspuns corect - încearcă din nou",
    "wrong": "Răspuns greșit - încearcă din nou",
    "correctAnswer": "Răspuns corect",
    "proposedAnswer": "Răspunsul tău",
    "level": "Alege nivelul de dificultate",
    "level1": "Ușor",
    "level2": "Mediu",
    "level3": "Dificil",
    "level4": "Foarte dificil",
    "easywords": "câine,pisică,întreabă,cine,afară,înăuntru,sus,nu",
    "mediumwords": "casă,acoperiș,fereastră,ușă,masă,scaun",
    "hardwords": "bicicletă,avion,între,dincolo de,prin",
    "complexwords": "sens giratoriu,înțelegere,complexitate,misterios,provocator"
  },
  "numbergame": {
    "title": "Ghicește numarul de {level} cifre",
    "explanation": "Încearcă să ghicești numărul secret de {level} cifre, cu cifre unice de la 1 la 9. - Ai 10 încercări",
    "guess": "Sugestie",
    "goods": "Bunuri",
    "corrects": "Comentariu",
    "placeholder": "Introdu o presupunere",
    "again": "Joacă",
    "toastmaxinput": "Maxim {level} cifre",
    "toastunique": "Te rog să introduci un număr de {level} cifre, cu cifre de la 1 la 9 (inclusiv)",
    "toastnoduplicate": "Nu e permisă duplicarea cifrelor",
    "reset": "Resetare",
    "tooltipguess": "Butonul verde indică faptul că una dintre cifrele pe care le-ai menționat este prezentă și plasată corect (nu este neapărat cifra aflată în locul indicat de buton). Butonul albastru indică faptul că una dintre cifrele pe care le-ai menționat este prezentă în cod, dar NU este în locul corect",
    "tooltipgoods": "Numarul de cifre corecte dar pozitionate gresit",
    "tooltipcorrects": "Alb este greșit, albastru este corect, verde este la locul corect",
    "win": "Ai câștigat! Numărul secret era: {number}",
    "lost": "Ai pierdut... Numărul secret era: {number}"
  },
  "toast": {
    "calmove": "Calendarul nu este actualizat, modificările calendarului sunt controlate din adminweb",
    "calchanged": "Calendarul nu este actualizat, modificările calendarului sunt controlate din adminweb",
    "webgamesaved": "Timpul petrecut în joc este salvat."
  },
  "selfreporting": {
    "completetitle": "Ai completat tot chestionarul",
    "completetext": "Felicitări, bravo!",
    "q1": {
      "q": "Cine completează acest chestionar?",
      "a1": "Utilizatorul principal",
      "a2": "Utilizatorul secundar intervievând utilizatorul principal",
      "a3": "Utilizatorul secundar pe baza observațiilor utilizatorului principal"
    },
    "q2": {
      "q": "Ieri și astăzi, ți-ai amintit să iei medicamentele?",
      "a1": "Cred că am luat toate medicamentele",
      "a2": "Mă tem că am uitat să iau majoritatea medicamentelor",
      "a3": "Cred ca am uitat sa iau niște medicamente",
      "a4": "Nu vreau să iau medicamentele",
      "a5": "Prefer să nu răspund"
    },
    "q3": {
      "q": "Cum ai descrie starea ta de spirit astăzi?",
      "a1": "Tensionat, nervos, iritat, enervat",
      "a2": "Plictisit, obosit, posomorât, trist",
      "a3": "Vesel, fericit, entuziasmat, plin de viață",
      "a4": "Calm, senin, relaxat, fara griji",
      "a5": "Prefer să nu răspund"
    },
    "q4": {
      "q": "Ai experimentat recent un sentiment de confuzie?",
      "a1": "Nu",
      "a2": "Câteva în ultimele zile",
      "a3": "Înrăutățire vizibilă în ultimele zile sau săptămâni",
      "a4": "Prefer să nu răspund"
    },
    "q5": {
      "q": "Ai avut recent probleme în a-ți aminti nume pe care ar trebui să le știi (rude apropiate, prieteni, membri ai familiei)?",
      "a1": "Nu",
      "a2": "Câteva în ultimele zile",
      "a3": "Înrăutățire vizibilă în ultimele zile sau săptămâni",
      "a4": "Prefer să nu răspund"
    },
    "q6": {
      "q": "Ai avut recent probleme cu ratacirea obiectelor acasă?",
      "a1": "Nu",
      "a2": "Câteva în ultimele zile",
      "a3": "Înrăutățire vizibilă în ultimele zile sau săptămâni",
      "a4": "Prefer să nu răspund"
    },
    "q7": {
      "q": "Ai avut recent probleme în a găsi obiecte acasă?",
      "a1": "Nu",
      "a2": "Câteva în ultimele zile",
      "a3": "Înrăutățire vizibilă în ultimele zile sau săptămâni",
      "a4": "Prefer să nu răspund"
    },
    "q8": {
      "q": "Ai avut recent probleme în a găsi drumul înapoi acasă când ai fost la plimbare?",
      "a1": "Nu",
      "a2": "Câteva în ultimele zile",
      "a3": "Înrăutățire vizibilă în ultimele zile sau săptămâni",
      "a4": "Prefer să nu răspund"
    },
    "q9": {
      "q": "Ai avut recent probleme în a-ți aminti cum să desfășori activități procedurale (de exemplu, folosirea cafetierei, a telecomenzii TV sau a mașinii de spălat)?",
      "a1": "Nu",
      "a2": "Câteva în ultimele zile",
      "a3": "Înrăutățire vizibilă în ultimele zile sau săptămâni",
      "a4": "Prefer să nu răspund"
    },
    "q10": {
      "q": "Ai avut recent probleme în a-ți aminti numele sau cuvintele pentru diverse lucruri, obiecte, locuri etc.?",
      "a1": "Nu",
      "a2": "Câteva în ultimele zile",
      "a3": "Înrăutățire vizibilă în ultimele zile sau săptămâni",
      "a4": "Prefer să nu răspund"
    },
    "q11": {
      "q": "Greață. Ai suferit de greață în ultimele zile?",
      "a1": "Nu, deloc",
      "a2": "Da, puțin sau uneori",
      "a3": "Da, destul de mult",
      "a4": "Prefer să nu răspund"
    },
    "q12": {
      "q": "Digestive. Ai avut probleme digestive în ultimele zile?",
      "a1": "Nu, deloc",
      "a2": "Da, puțin sau uneori",
      "a3": "Da, destul de mult",
      "a4": "Prefer să nu răspund"
    },
    "q13": {
      "q": "Apetit scăzut. Ai avut în ultimele zile un apetit scăzut?",
      "a1": "Nu, deloc",
      "a2": "Da, puțin sau uneori",
      "a3": "Da, destul de mult",
      "a4": "Prefer să nu răspund"
    },
    "q14": {
      "q": "Amețeli. Ai suferit de amețeli în ultimele zile?",
      "a1": "Nu, deloc",
      "a2": "Da, puțin sau uneori",
      "a3": "Da, destul de mult",
      "a4": "Prefer să nu răspund"
    },
    "q15": {
      "q": "Fără putere. Te-ai simțit fără putere în ultimele zile?",
      "a1": "Nu, deloc",
      "a2": "Da, puțin sau uneori",
      "a3": "Da, destul de mult",
      "a4": "Prefer să nu răspund"
    },
    "q16": {
      "q": "Somn. Ai avut probleme cu somnul în ultimele zile?",
      "a1": "Nu, deloc",
      "a2": "Da, puțin sau uneori",
      "a3": "Da, destul de mult",
      "a4": "Prefer să nu răspund"
    },
    "q17": {
      "q": "Limbaj. Ai avut în ultimele zile dificultăți în a te exprima, de exemplu dificultăți în a găsi cuvintele potrivite, dificultăți de înțelegere sau senzația de a da răspunsuri vagi și incomplete?",
      "a1": "Nu, deloc",
      "a2": "Da, puțin sau uneori",
      "a3": "Da, destul de mult",
      "a4": "Prefer să nu răspund"
    },
    "q18": {
      "q": "Raționament. Ai avut în ultimele zile probleme cu raționamentul și judecata, de exemplu, judecată slabă în cheltuirea banilor sau o îngrijire personală deficitară?",
      "a1": "Nu, deloc",
      "a2": "Da, puțin sau uneori",
      "a3": "Da, destul de mult",
      "a4": "Prefer să nu răspund"
    },
    "q19": {
      "q": "Social. Ai avut în ultimele zile probleme în a păstra relațiile sociale sau în a-ți desfășura hobby-urile și activitățile preferate?”",
      "a1": "Nu, deloc",
      "a2": "Da, puțin sau uneori",
      "a3": "Da, destul de mult",
      "a4": "Prefer să nu răspund"
    }
  }
}
