// import { ref, computed } from "vue";
import { defineStore } from 'pinia'

import { userState } from './userstore/userState.js'
import { userGetters } from './userstore/userGetters.js'
import { userActions } from './userstore/userActions.js'

export const userStore = defineStore('userstore', {
  state: () => userState,
  getters: userGetters,
  actions: userActions
})
