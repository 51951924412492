import { createApp } from 'vue'
import { createPinia } from 'pinia'
import i18n from '@/i18n/i18n-setup.js'
import vuetify from '@/plugins/vuetify.js'

import App from './App.vue'
import router from './router'
import dayjs from '@/plugins/dayjs'


import Toast from "vue-toastification"
// Import the CSS or use your own!
import "vue-toastification/dist/index.css"

//https://vite-pwa-org.netlify.app/guide/prompt-for-update.html
// import { registerSW } from 'virtual:pwa-register'

// import vue3GoogleLogin from 'vue3-google-login'

console.log('=============== VUE CONFIG JS ========  ' + import.meta.url)
console.log(
  'viteNode(%s) env.dev (%s) prod(%s)',
  import.meta.env.VITE_NODE_ENV,
  import.meta.env.DEV,
  import.meta.env.PROD
)

import './assets/main.css'
import '@mdi/font/css/materialdesignicons.css'
// import './registerServiceWorker.js'

import { registerSW } from 'virtual:pwa-register'


const toastOptions = {
  timeout: 4500,
  maxToasts: 10,
  newestOnTop: true,
  showCloseButtonOnHover: true,
  position: 'top-center'
}

const updateSW = registerSW({
  immediate: true,
  onNeedRefresh() {},
  onOfflineReady() {}
})
const app = createApp(App)
const pinia = createPinia()

app.config.globalProperties.$date = dayjs

/*
const gAuthClientId =
  '1040330893434-er6ul24q6reiok10t2e1ak5rndvkeqqe.apps.googleusercontent.com'

app.use(gAuthPlugin, {
  clientId: gAuthClientId,
  scope: 'email',
  prompt: 'consent'
})
*/
// updateSW()

app.use(updateSW)
app.use(pinia)
app.use(router)
app.use(i18n)
app.use(vuetify)

app.use(Toast, toastOptions)

app.mount('#app')
