export const adminGetters = {
  getTest(state) {
    return state.test
  },
  getActiveNavItem: (state) => state.activenavitem,
  getUiLevel(state) {
    return state.uilevel
  },
  getLang(state) {
     switch (state.lang) {
      case 'nb-NO':
        return 'nb'
      default:
          return state.lang
    }
  },
  getServername(state) {
    return state.servername
  },
  getServerUrl(state) {
    return state.serverurl
  },
  getRegistryUrl(state) {
    return state.registryurl
  },
  getSid(state) {
    return state.sid
  },
  getPublicKey(state) {
    return state.publickey
  },
  getAppSecret(state) {
    return state.appsecret
  },
  getIsDisabled(state) {
    return state.isdisabled
  },
  getIsPhone(state) {
    return state.isPhone
  },
  getRadioChannel(state) {
    return state.radioChannel
  },
   getShowRadioControls(state) {
    return state.showRadioControls
  },
  viewRadioControls(state) {
    return state.showRadioControls
  }
}
