{
  "companyname": "Karde AS",
  "nav": {
    "nav0": "Home",
    "nav1": "Info su Memas",
    "nav2": "Privacy",
    "nav3": "???"
  },
  "gui": {
    "appname": "Memas adminweb",
    "close": "Chiudi",
    "landingpageheader": "Benvenuto su Memas!",
    "firsttimeheader": "Registrazione iniziale",
    "offlineheading": "Sei offline",
    "aboutheader": "Info su Memas",
    "privacyheader": "Privacy",
    "offlinetext": "Attendi finché non sei di nuovo online",
    "onlineheading": "Sei online",
    "onlinetext": "Puoi continuare",
    "primaryusers": "Utente principale",
    "calendar": "Calendario",
    "pcalendar": "Oggi",
    "memorygames": "Allenamento cognitivo",
    "images": "Immagini e categorie di immagini",
    "pleasure": "Piacere",
    "contact": "Contatto",
    "selfreporting": "Self-reporting",
    "dailyhelp": "Supporto giornaliero",
    "usermanuals": "Manuale utente",
    "primarylanding": "Utente principale di Memas",
    "of": "di",
    "question": "Domanda",
    "wrongOTPcode": "Il codice inserito non esiste - Prova di nuovo",
    "missingValue": "Devi selezionare una risposta prima di continuare",
    "nextquestion": "Successivo",
    "previousquestion": "Precedente",
    "donequestions": "Torna alla schermata iniziale",
    "maxscreen": "Schermo pieno",
    "imagelibrary": "Foto",
    "noimagelibrary": "Nessuna foto disponibile",
    "radiostations": "Radio",
    "noradiostations": "Stazioni radio disponibili",
    "videolibrary": "Video",
    "novideolibrary": "Video disponibili",
    "stop": "Stop",
    "pause": "Pausa",
    "play": "Start",
    "calnav": "Giorni",
    "calmonth": "Mese",
    "contactinformation": "Contatto principale",
    "addcalendarevent": "Nuovo appuntamento",
    "todayis": "Oggi è "
  },
  "time": {
    "beforelunch": "Prima di pranzo",
    "lunch": "Pranzo",
    "afterlunch": "Dopo pranzo",
    "dinner": "Cena",
    "afternoon": "Pomeriggio",
    "evening": "Sera",
    "night": "Notte",
    "morning": "Mattina"
  },
  "toast": {
    "calmove": "Il calendario non viene aggiornato, le modifiche al calendario sono controllate dall'adminweb.",
    "calchanged": "Il calendario non viene aggiornato, le modifiche al calendario sono controllate dall'adminweb.",
    "webgamesaved": "Il tempo impiegato nel gioco viene salvato"
  },
  "wordgame": {
    "title": "Riformulare la parola",
    "startgame": "Fare clic per iniziare il gioco",
    "correct": "Risposta corretta: riprova",
    "wrong": "Risposta sbagliata: riprova",
    "correctAnswer": "Risposta corretta",
    "proposedAnswer": "La tua risposta",
    "level": "Scegli il livello di difficoltà",
    "level1": "Facile",
    "level2": "Medio",
    "level3": "Difficile",
    "level4": "Stimolante",
    "easywords": "bel,bio,gel,lec,cie,dol,sec,via,odi,neo,mio",
    "mediumwords": "bello,biologo,fidato,lecito,cieco,dolce,secondo,viaggio,odioso,miope",
    "hardwords": "neologismo,comodo,grande,robusto,stupido,pigro,lento,leggero",
    "complexwords": "promiscuità,coinquilino,esagerare,chiacchiericcio,fagiolini,iscriversi,impacciato"
  },
  "numbergame": {
    "title": "Indovina il numero di {level} cifre",
    "explanation": "Prova a indovinare il numero segreto di {level} cifre con cifre univoche da 1 a 9. - Hai 10 tentativi.",
    "guess": "Suggerimento",
    "goods": "Buone risposte",
    "corrects": "Feedback",
    "placeholder": "Inserisci la tua ipotesi",
    "again": "Gioca di nuovo",
    "toastmaxinput": "Massimo {level} cifre",
    "toastunique": "Inserisci un numero di {level} cifre con cifre da 1 a 9 (incluso)",
    "toastnoduplicate": "Non sono consentite cifre duplicate",
    "reset": "Resetta",
    "tooltipguess": "Il pulsante verde indica che uno dei numeri che hai indicato è presente e posizionato correttamente (non è necessariamente il numero che si trova nel posto indicato dal pulsante). Il pulsante blu indica che una delle cifre da te indicate è presente nel codice, ma NON al posto giusto.",
    "tooltipgoods": "Numero di numeri corretti nella posizione sbagliata",
    "tooltipcorrects": "Il bianco è sbagliato, il blu è il numero corretto, il verde è nella posizione giusta",
    "win": "Hai vinto! Il numero segreto era: {number}",
    "lost": "Hai perso... Il numero segreto era: {number}"
  },
  "ms": {
    "title": "Dragamine",
    "playagain": "Gioca di nuovo?",
    "yes": "SÌ",
    "win": "Hai vinto",
    "loose": "Hai perso...",
    "instructions": "Evita le mine, seleziona una cella, fai clic con il pulsante destro del mouse per contrassegnare una mina."
  },
  "tooltip": {
    "help": "Supporto contestualizzato per l'utilizzo di Memas"
  },
  "primary": {
    "entercode": "Inserisci il codice per iniziare ad usare Memas"
  },
  "secondaryuser": {
    "heading": "Registrazione degli utenti secondari",
    "displayname": "Nome",
    "email": "E-mail",
    "phone": "Telefono",
    "skype": "Skype",
    "address": "Indirizzo",
    "postcode": "CAP",
    "place": "Città",
    "country": "Paese",
    "subscriptiontype": "Tipo di contratto",
    "subscriptionmodel1": "Utilizzo gratuito - 30 giorni",
    "subscriptionmodel2": "Contratto trimestrale - rinnovo automatico",
    "subscriptionmodel3": "Contratto annuale - rinnovo automatico"
  },
  "primaryuser": {
    "heading": "Registrazione degli utenti principali",
    "displayname": "Nome",
    "email": "E-mail",
    "phone": "Telefono",
    "skype": "Skype",
    "address": "Indirizzo",
    "postcode": "CAP",
    "place": "Città",
    "country": "Paese",
    "registercode": "Codice di registrazione"
  },
  "cal": {
    "starttime": "Orario d'inizio",
    "endtime": "Orario di fine",
    "title": "Titolo dell'evento",
    "alldayevent": "Evento ricorrente",
    "explanation": "Più informazioni",
    "image": "Immagine della persona associata all'evento",
    "nocaldatatoday": "Nessun evento in calendario per oggi"
  },
  "help": {
    "title": "Come usare Memas",
    "headerhelp": "Supporto contestualizzato"
  },
  "selfreporting": {
    "completetitle": "Hai completato il questionario",
    "completetext": "Congratulazioni, ben fatto!",
    "q1": {
      "q": "Chi ha risposto alle domande?",
      "a1": "L'utente da solo",
      "a2": "Il caregiver chiedendo all'utente",
      "a3": "Il caregiver basandosi sull'osservazione dell'utente"
    },
    "q2": {
      "q": "Ti sei ricordato/a di prendere le medicine ieri ed oggi?",
      "a1": "Sono sicuro di aver preso tutte le medicine",
      "a2": "Credo di aver dimenticato alcune medicine",
      "a3": "Temo di aver dimenticato molte medicine",
      "a4": "Non voglio prendere le medicine",
      "a5": "Preferisco non rispondere"
    },
    "q3": {
      "q": "Come descriveresti il tuo umore oggi?",
      "a1": "Calmo, sereno, rilassato, spensierato",
      "a2": "Allegro, felice, entusiasta, vivace.",
      "a3": "Annoiato, stanco, cupo, triste.",
      "a4": "Teso, nervoso, irritato, infastidito",
      "a5": "Preferisco non rispondere"
    },
    "q4": {
      "q": "Ti sei sentito confuso ultimamente?",
      "a1": "Nessun cambiamento",
      "a2": "Alcuni peggioramenti negli ultimi giorni o settimane",
      "a3": "Notevoli peggioramenti negli ultimi giorni o settimane",
      "a4": "Preferisco non rispondere"
    },
    "q5": {
      "q": "Ultimamente hai avuto problemi nel ricordare dei nomi di familiari o amici?",
      "a1": "Nessun cambiamento",
      "a2": "Alcuni peggioramenti negli ultimi giorni o settimane",
      "a3": "Notevoli peggioramenti negli ultimi giorni o settimane",
      "a4": "Preferisco non rispondere"
    },
    "q6": {
      "q": "Ultimamente hai smarrito degli oggetti in casa?",
      "a1": "Nessun cambiamento",
      "a2": "Alcuni peggioramenti negli ultimi giorni o settimane",
      "a3": "Notevoli peggioramenti negli ultimi giorni o settimane",
      "a4": "Preferisco non rispondere"
    },
    "q7": {
      "q": "Ultimamente hai avuto problemi nel trovare oggetti in casa?",
      "a1": "Nessun cambiamento",
      "a2": "Alcuni peggioramenti negli ultimi giorni o settimane",
      "a3": "Notevoli peggioramenti negli ultimi giorni o settimane",
      "a4": "Preferisco non rispondere"
    },
    "q8": {
      "q": "Ultimamente hai avuto problemi nel ricordare la strada per tornare a casa?",
      "a1": "Nessun cambiamento",
      "a2": "Alcuni peggioramenti negli ultimi giorni o settimane",
      "a3": "Notevoli peggioramenti negli ultimi giorni o settimane",
      "a4": "Preferisco non rispondere"
    },
    "q9": {
      "q": "Ultimamente hai avuto problemi nel fare il caffè, guardare la TV o fare il bucato?",
      "a1": "Nessun cambiamento",
      "a2": "Alcuni peggioramenti negli ultimi giorni o settimane",
      "a3": "Notevoli peggioramenti negli ultimi giorni o settimane",
      "a4": "Preferisco non rispondere"
    },
    "q10": {
      "q": "Ultimamente hai avuto problemi nel ricordare i nomi delle cose o dei posti?",
      "a1": "Nessun cambiamento",
      "a2": "Alcuni peggioramenti negli ultimi giorni o settimane",
      "a3": "Notevoli peggioramenti negli ultimi giorni o settimane",
      "a4": "Preferisco non rispondere"
    },
    "q11": {
      "q": "Hai sofferto di nausea negli ultimi giorni?",
      "a1": "No",
      "a2": "Un po'",
      "a3": "Molto",
      "a4": "Preferisco non rispondere"
    },
    "q12": {
      "q": "Hai sofferto di diarrea negli ultimi giorni?",
      "a1": "No",
      "a2": "Un po'",
      "a3": "Molto",
      "a4": "Preferisco non rispondere"
    },
    "q13": {
      "q": "Ultimamente hai sofferto di scarso appetito?",
      "a1": "No",
      "a2": "Un po'",
      "a3": "Molto",
      "a4": "Preferisco non rispondere"
    },
    "q14": {
      "q": "Hai avuto le vertigini ultimamente?",
      "a1": "No",
      "a2": "Un po'",
      "a3": "Molto",
      "a4": "Preferisco non rispondere"
    },
    "q15": {
      "q": "Ultimamente ti sei sentito senza forze, stanco?",
      "a1": "No",
      "a2": "Un po'",
      "a3": "Molto",
      "a4": "Preferisco non rispondere"
    },
    "q16": {
      "q": "Hai avuto problemi nel riposare durante gli ultimi giorni?",
      "a1": "No",
      "a2": "Un po'",
      "a3": "Molto",
      "a4": "Preferisco non rispondere"
    },
    "q17": {
      "q": "Negli ultimi giorni ti sei sentito in difficoltà nell'esprimerti, cioè trovare le parole giuste, essere capito, o la sensazione di ricevere risposte incomplete e vaghe?",
      "a1": "No",
      "a2": "Un po'",
      "a3": "Molto",
      "a4": "Preferisco non rispondere"
    },
    "q18": {
      "q": "Negli ultimi giorni hai avuto difficoltà nel ragionare o riflettere, come ad esempio nel controllare le spese o prenderti cura di te stesso/a?",
      "a1": "No",
      "a2": "Un po'",
      "a3": "Molto",
      "a4": "Preferisco non rispondere"
    },
    "q19": {
      "q": "negli ultimi giorni hai avuto difficoltà a mantenere il contatto con i tuoi amici o familiari, oppure a fare alcune attività?",
      "a1": "No",
      "a2": "Un po'",
      "a3": "Molto",
      "a4": "Preferisco non rispondere"
    }
  },
  "icon": {
    "save": "mdi-content-save-outline",
    "schemaupdate": "mdi-update",
    "close": "mdi-close",
    "help": "mdi-help",
    "next": "mdi-arrow-right-bold",
    "previous": "mdi-arrow-left-bold",
    "dots": "mdi-dots-vertical",
    "tryagain": "mdi-refresh",
    "maxscreen": "mdi-fullscreen",
    "stop": "mdi-radio-off",
    "pause": "mdi-pause",
    "play": "mdi-play"
  }
}
