console.log('*** START *** BASE 64')

// const base64 = require('base64-url')
// const {base64encode, base64decode} = require('nodejs-base64')
// const crypto = require('crypto')

// const CreateHypatiaJWE = require('./createJWT')
// const jsonwebtoken = require('jsonwebtoken')
// const dbSessionStore = require('../lib/sessionStore')

import { Buffer } from 'buffer'

function base64encode(str) {
  if (typeof str !== 'string') {
    if (typeof str === 'number') {
      str = str.toString()
    } else {
      throw new Error('Text to encode must be a string or a number.')
    }
  }
  return Buffer.from(str, 'utf8').toString('base64')
}

function base64decode(str) {
  if (typeof str !== 'string') {
    throw new Error('Input value must be a string.')
  }
  return Buffer.from(str, 'base64').toString('utf8')
}

// var hypCrypt = new HypCrypt(null)
export function decodeLoad(load) {
  // console.log('DECODE ORG got called')
  /// console.log(load)
  // could also be extended to do checking of load
  // could also be extended to do decrypting of the load
  // console.log('====== LOAD =======')
  // console.log(load)
  if (load === undefined || load === null || load.length === 0) {
    console.warn('==== W A R N ==== load is (%s)', load)
    return ''
  }
  // return base64-decode(load)
  return base64decode(load)
}

export function encodeLoad(load) {
  // const base64 = require('base64-url')
  // could also be used to verify and check more data being transmitted.
  // could also be enhanced to encrypt the load if necessary.
  // return base64.encode(load)
  return base64encode(load)
  // return encodeURI(load)
  // return load
}

const encodeBuffer = (buffer) => buffer.toString('base64')
const encodeString = (string) => encodeBuffer(Buffer.from(string))
const encodeData = (data) => encodeString(JSON.stringify(data))

export const encode = (data) => {
  if (Buffer.isBuffer(data)) return encodeBuffer(data)
  if (typeof data === 'string') return encodeString(data)
  return encodeData(data)
}

export const decode = (string) => {
  const decoded = Buffer.from(string, 'base64').toString()
  try {
    return JSON.parse(decoded)
  } catch (e) {
    return decoded
  }
}

console.log('*** DONE *** BASE 64')

export default {
  decodeLoad,
  encodeLoad,
  encode,
  decode
}
